﻿export class UrlManager implements IUrlManager {

	baseUrl: string;
	rootUrl: string;

    constructor(baseUrl: string, rootUrl: string) {
        let me: this = this;

		me.baseUrl = baseUrl;
		me.rootUrl = rootUrl;
    } 

    replaceState(data: any, title: string, url: string):void {
        let me:this = this;

        if (me.isHistoryManagementAvailable()) {
            window.history.replaceState(data, title, url);
        }
    }

    pushState(data: any, title: string, url: string):void {
        let me:this = this;

        if (me.isHistoryManagementAvailable()) {
            window.history.pushState(data, title, url);
        }
    }

    isHistoryManagementAvailable():boolean {
        return typeof window.history === "object" && typeof window.history.pushState === "function";
    }

    encodeUriComponent(urlPart:string):string {
        return encodeURIComponent(urlPart);
    }

	updateRegion(regionCode: string): void {
		if (this.baseUrl == this.rootUrl) {
			return;
		}
		let postRootPath = this.baseUrl.replace(this.rootUrl, '');
		let regionDivider = postRootPath.indexOf('/');
		let postRegionPath = postRootPath.substr(regionDivider);
		let updatedHref = this.rootUrl + regionCode.toLowerCase() + postRegionPath;
        window.location.href = updatedHref;
    }

    getAbsoluteUrl(pathAndQueryString: string): string {
        let me: this = this,
            pathQS = pathAndQueryString.split("?"),
            path = pathQS[0],
            queryString = pathQS[1] || "";

        return me.ensureTrailingSlash(me.ensureTrailingSlash(this.baseUrl) + me.ensureNoBeginningSlash(path)) + (queryString != "" ? "?" + queryString : "");
    }

    ensureTrailingSlash(url: string): string {
        return url.replace(/\/?$/, "/");
    }

    ensureNoBeginningSlash(url: string): string {
        return url.replace(/^\/?/, "");
    }

}
