﻿import { PresenterList } from "../ts/Arlo/Pages/PresenterList";
import { VenueList } from "../ts/Arlo/Pages/VenueList";
import { SearchList } from "../ts/Arlo/Pages/SearchList";
import { UpcomingList } from "../ts/Arlo/Pages/UpcomingList";
import { Catalogue } from "./Arlo/Pages/Catalogue";
import { HomePage } from "./Arlo/Pages/HomePage";
import { EventTemplate } from "./Arlo/Pages/EventTemplate";
import { OnlineActivities } from "./Arlo/Pages/OnlineActivities";
import { Webinars } from "./Arlo/Pages/Webinars";
import { Schedule } from "./Arlo/Pages/Schedule";
import { CertificationSearch } from "./Arlo/Pages/CertificationSearch";

import { CookieManager } from "../ts/Arlo/Utilities/CookieManager";
import { UrlManager } from "../ts/Arlo/Utilities/UrlManager";
import { MessageManager } from "../ts/Arlo/Utilities/MessageManager";
import { Utilities } from "../ts/Arlo/Utilities/Utilities";
import { ListPageHelperFactory } from "../ts/Arlo/Utilities/ListPageHelperFactory";
import { ApiClient } from "../ts/Arlo/ApiClient";
import { SelectizeHelper } from "./Arlo/Utilities/SelectizeHelper";
import { CertificationSearchService } from "./Arlo/Utilities/CertificationSearchService";
import { WalkMeBubbleHelper } from "./Arlo/Utilities/WalkMeBubbleHelper";

import AuthenticationManager from './Arlo/Utilities/AuthenticationManager'

/* external libraries */
declare var toastr: any;
declare var Cookies: any;
declare var chroma: any;

export class StarterTemplate {

    cookieManager: ICookieManager;
    urlManager: IUrlManager;
    utilities: Utilities;
    apiClient: IApiClient;
    messageManager: IMessageManager;
    listPageHelper: ListPageHelperFactory;
    selectizeHelper: SelectizeHelper;
    certificationSearchService: ICertificationSearchService;
    walkmeBubbleHelper: WalkMeBubbleHelper;

    presenterList: PresenterList;
    venueList: VenueList;
    searchList: SearchList;
    upcomingList: UpcomingList;
    catalogue: Catalogue;
    homePage: HomePage;
    onlineActivities: OnlineActivities;
    webinars: Webinars;
    schedule: Schedule;
    eventTemplate: EventTemplate;
    certificationSearch: CertificationSearch;

    authenticationManager: AuthenticationManager;

    constructor(config: IStarterTemplateConfig) {
        let me: this = this;

        if (!config.baseUrl || !config.controllerConfig || !config.filterPrefixConfig) {
            throw "not correct configuration";
        }

        me.cookieManager = new CookieManager(Cookies);
        me.urlManager = new UrlManager(config.baseUrl, config.rootUrl);
        me.utilities = new Utilities(chroma);
        me.apiClient = new ApiClient();
        me.messageManager = new MessageManager(toastr);
        me.listPageHelper = new ListPageHelperFactory(me.urlManager, me.utilities, me.apiClient, me.messageManager);
        me.selectizeHelper = new SelectizeHelper(me.utilities);
        me.certificationSearchService = new CertificationSearchService(config.pageConfig.baseUrl, me.utilities, me.apiClient);
        me.walkmeBubbleHelper = new WalkMeBubbleHelper();

		me.presenterList = new PresenterList(me.listPageHelper, config.pageID, config.controllerConfig.presenterControllerUri);
		me.venueList = new VenueList(me.listPageHelper, config.pageID, config.controllerConfig.venueControllerUri, me.urlManager, me.apiClient);
        me.searchList = new SearchList(me.apiClient, me.utilities, me.urlManager, me.messageManager, me.selectizeHelper, config.controllerConfig.searchControllerUri, config.filterPrefixConfig);
        me.upcomingList = new UpcomingList(me.apiClient, me.utilities, me.urlManager, me.selectizeHelper,
            me.messageManager, me.cookieManager, config.pageID, config.controllerConfig.upcomingControllerUri, config.filterPrefixConfig);
        me.catalogue = new Catalogue(me.apiClient, me.utilities, me.urlManager, me.searchList, me.messageManager, config.pageID, config.controllerConfig.catalogueControllerUri, config.filterPrefixConfig, config.templateQueriesEnabled);
        me.schedule = new Schedule(me.cookieManager, me.apiClient, me.utilities, me.urlManager, me.selectizeHelper, me.messageManager, config.pageID, config.controllerConfig.scheduleControllerUri, config.filterPrefixConfig);
        me.homePage = new HomePage(me.utilities, me.searchList, me.selectizeHelper, chroma);
        me.eventTemplate = new EventTemplate(me.utilities, me.selectizeHelper, me.cookieManager);
        me.onlineActivities = new OnlineActivities(me.apiClient, me.utilities, me.urlManager, me.messageManager, me.selectizeHelper, config.pageID, config.controllerConfig.onlineActivitiesControllerUri, config.filterPrefixConfig);
        me.webinars = new Webinars(me.apiClient, me.utilities, me.urlManager, me.messageManager, me.cookieManager, me.selectizeHelper, config.pageID, config.controllerConfig.webinarControllerUri, config.filterPrefixConfig);
        me.certificationSearch = new CertificationSearch(me.utilities, me.certificationSearchService, me.messageManager);
        me.authenticationManager = new AuthenticationManager(config.portalEnabled, config.baseUrl);

        me.utilities.initHeaderBackgrouncCls();
        me.walkmeBubbleHelper.initWalkMeBubble();

        //cheap, but works TODO: Need to rewrite to set the filters from the url
        window.addEventListener('popstate', function (e) {
            document.location.reload();
        });

        jQuery(document).ready(function () {
            jQuery(".header-inner .menu-more").click((event: JQuery.Event) => {
                event.preventDefault();
            });
        });

    }

    getPageConfig(page: string, config: object): object {
        let key = page + "Config",
            configValue = {};

        if (config.hasOwnProperty(key)) {
            configValue = config[key];
        }

        return configValue;
    }
}

/* tslint:disable */
declare global {
    interface Window {
        ArloStarterTemplate: typeof StarterTemplate;
    }
}
/* tslint:enable */

window.ArloStarterTemplate = StarterTemplate;
