﻿import { UrlManager } from "./UrlManager";
import { Utilities } from "../Utilities/Utilities";
import { InViewPort } from "../Utilities/InViewPort";
import { ApiClient } from "../ApiClient";

export class ListPageHelper {
    readonly loadMoreButtonCls:string = "loadmore";
    readonly loadMoreButtonContainerCls:string = "loadmore-container";
    readonly loadPrevButtonCls:string = "loadprev";
    readonly loadPrevButtonContainerCls:string = "loadprev-container";

    urlManager: IUrlManager;
    utilities: Utilities;
    apiClient: IApiClient;
    messageManager: IMessageManager;

    containerCls: string;
    itemCls: string;

    containerElement: HTMLElement;
    loadMoreButtonElement: HTMLElement;
    loadPrevButtonElement: HTMLElement;
    page: number;
    controllerUri: string;
    action: string;
    isLoadMoreAvailable:boolean;
    isLoadPrevAvailable: boolean;
    isNextLoading:boolean;
	isPrevLoading: boolean;
	isReloading: boolean;
	parameterKeys: string[] = null;
	parameterValues = null;

    constructor(containerCls: string, itemCls: string, controllerUri: string, action: string, page: number,
        apiClient:IApiClient, urlManager: IUrlManager, messageManager: IMessageManager, utilities:Utilities) {
        let me:this = this,
            element:JQuery<HTMLElement> = jQuery(`.${containerCls}`);

        me.urlManager = urlManager;
        me.utilities = utilities;
        me.apiClient = apiClient;
        me.messageManager = messageManager;

        me.page = page != null ? page : 1;
        me.controllerUri = controllerUri;
        me.action = action;
        me.itemCls = itemCls;

        me.isLoadMoreAvailable = false;
        me.isLoadPrevAvailable = false;
        me.isNextLoading = false;
		me.isPrevLoading = false;
		me.isReloading = false;

		me.parameterKeys = [];
		me.parameterValues = {};

        if (element.length === 1) {
            let moreBtnElement:JQuery<HTMLElement> = element
                                                    .next().find(`.${me.loadMoreButtonContainerCls}`)
                                                    .find(`.${me.loadMoreButtonCls}`),
                prevBtnElement:JQuery<HTMLElement> = element
                                                    .prev().find(`.${me.loadPrevButtonContainerCls}`)
                                                    .find(`.${me.loadPrevButtonCls}`);

            me.containerCls = containerCls;
            me.containerElement = element[0];

            if (moreBtnElement.length === 1 || prevBtnElement.length === 1) {
                if (moreBtnElement.length === 1) {
                    me.loadMoreButtonElement = moreBtnElement[0];
                    me.isLoadMoreAvailable = true;
                }

                if (prevBtnElement.length === 1) {
                    me.loadPrevButtonElement = prevBtnElement[0];
                    me.isLoadPrevAvailable = true;
                }

                me.initClickEvent();
                me.initScrollEvent();
            }
        }
    }

    initClickEvent():void {
        let me:this = this;

        jQuery(me.loadMoreButtonElement).click((event: JQuery.Event) => {
            event.stopPropagation();
            event.stopImmediatePropagation();
            me.loadNext();
            return false;
        });

        jQuery(me.loadPrevButtonElement).click((event: JQuery.Event) => {
            event.stopPropagation();
            event.stopImmediatePropagation();
            me.loadPrev();
            return false;
        });
    }

    initScrollEvent():void {
        let me:this = this,
            loadMore:Function = me.utilities.debounce(():void => {
                me.needToLoadNext();
            }, 100, false),
            loadPrev:Function = me.utilities.debounce(():void => {
                me.needToLoadPrev();
            }, 100, false),
            setUrl:Function = me.utilities.debounce(():void => {
                me.setUrlFromVisiblePage();
            }, 100, false);

        window.onscroll = (ev: UIEvent): any => {
            setUrl();
            loadMore();
            loadPrev();
       };
    }

    needToLoadNext():void {
        let me:this = this,
            penultItem:JQuery<HTMLElement> = jQuery(`.${me.containerCls} .list-page:last .${me.itemCls}:nth-last-child(2)`);
        if (me.isNextLoading === false && penultItem.length === 1) {
            if (InViewPort.isAnyPartOfElementInViewport(penultItem[0])) {
                me.loadNext();
            }
        }
    }

    needToLoadPrev():void {
        let me: this = this,
            loadPrevButton:JQuery<HTMLElement> = jQuery(`.${me.containerCls}`)
                                            .prev().find(`.${me.loadPrevButtonContainerCls}`)
                                            .find(`.${me.loadPrevButtonCls}`);
        if (me.isPrevLoading === false && loadPrevButton.length === 1) {
            if (InViewPort.isAnyPartOfElementInViewport(loadPrevButton[0])) {
                me.loadPrev();
            }
        }
    }

    loadData(page: number, buttonElement:HTMLElement, callback:any):void {
        let me:this = this,
            url:string = `/${me.controllerUri}/${me.action}/?pageID=${page}`,
            apiCallback:IApiCallback = {
                success: (data:any):void => {
                    if (callback.hasOwnProperty("success") && typeof callback.success === "function") {
                        callback.success.call(me, data);
                    }

                    jQuery(buttonElement).attr("href", `/${me.controllerUri}/page-${page}`);
                },
                complete: (textStatus: string) => {
                    jQuery(buttonElement).find(".loader").hide();

                    if (callback.hasOwnProperty("complete") && typeof callback.complete === "function") {
                        callback.complete.call(me);
                    }
                },
                error:(textStatus: string, errorThrown: string) => {
                    me.messageManager.showApiErrorMessage(errorThrown);
                },
                scope: me
			};
		me.parameterKeys.forEach(function (key) {
			let value = me.parameterValues[key];
			url = url + "&" + key + "=" + me.urlManager.encodeUriComponent(value);
		});


        jQuery(buttonElement).find(".loader").css("display", "inline-block");

        me.apiClient.getData(me.urlManager.getAbsoluteUrl(url), apiCallback);
	}

	reload(): void {

		let me: this = this,
			page: number = 1,
			callback: any = {
				success: (data: any) => {
					jQuery(me.containerElement).find(`.list-page`).remove();
					jQuery(me.containerElement).append(jQuery("<div>", { "data-paging": data.page, "class": "list-page" }).html(data.html));
					

					me.isLoadMoreAvailable = page < data.totalPages;
					if (!me.isLoadMoreAvailable) {
						jQuery(me.loadMoreButtonElement).remove();
					}
				},
				complete: () => {
					me.page = 1;
					me.isReloading = false;
				}
			};

		if (!me.isReloading) {
			me.isReloading = true;
			me.loadData(page, me.loadMoreButtonElement, callback);
		}
	}

    loadNext():void {
        let me:this = this,
            page:number = me.page + 1,
            callback:any = {
                success: (data:any) => {
                    jQuery(me.containerElement).append(jQuery("<div>", {"data-paging": data.page, "class": "list-page"}).html(data.html));

                    me.isLoadMoreAvailable = page < data.totalPages;
                    if (!me.isLoadMoreAvailable) {
                        jQuery(me.loadMoreButtonElement).remove();
                    }
                },
                complete: () => {
                    me.isNextLoading = false;
                }
            };

        if (me.isLoadMoreAvailable && !me.isNextLoading) {
            me.loadData(page, me.loadMoreButtonElement, callback);
        }
    }

    loadPrev():void {
        let me:this = this,
            page:number = me.page - 1,
            callback:any = {
                success: (data:any) => {
                    let headerHeight:number = jQuery("nav.header:not(.minimized)").outerHeight();
                    jQuery(me.containerElement).prepend(jQuery("<div>", {"data-paging": data.page, "class": "list-page"}).html(data.html));

                    // adjust scroll position
                    let itemHeight:number = jQuery(me.containerElement).find(".list-page:first").height();
                    window.scrollTo(0, jQuery(window).scrollTop() + itemHeight
                            + (headerHeight != null ? headerHeight + 50 : 0)); // adjust scroll

                    me.isLoadPrevAvailable = page > 1;

                    if (!me.isLoadPrevAvailable) {
                        jQuery(me.loadPrevButtonElement).remove();
                    }
                },
                complete: () => {
                    me.isPrevLoading = false;
                }
            };

        if (me.isLoadPrevAvailable && page > 0 && !me.isPrevLoading) {
            me.loadData(page, me.loadPrevButtonElement, callback);
        }
    }

    setUrlFromVisiblePage():void {
        let me:this = this,
            pages:JQuery<HTMLElement> = jQuery(`.${me.containerCls} .list-page`);

        pages.each((index, element) => {
            if (InViewPort.isElementMostlyVisible(element)) {
                let page:number = parseInt(jQuery(element).data("paging"), 10);
                if (page !== me.page) {
                    me.page = page;
                    me.urlManager.pushState(null, document.title, `/${me.controllerUri}/page-${page}`);
                }

                return false;
            }
        });
    }
}
