declare var global:object;

export class ApiClient implements IApiClient {
    private data(url: string, method: Method, data: any, callback: IApiCallback, dataType: DataType):void {
        jQuery.ajax({
            url: url,
            dataType: dataType,
            type: "GET",
            success: function (data:any, textStatus: string):void {
                if (callback && callback.success && typeof callback.success === "function") {
                    callback.success.call(callback.scope || global, data, textStatus);
                }
            },
            complete: function(jqXHR: any, textStatus: string):void {
                if (callback && callback.complete && typeof callback.complete === "function") {
                    callback.complete.call(callback.scope || global, textStatus, jqXHR);
                }
            },
            error: function(jqXHR: any, textStatus: string, errorThrown: string):void {
                if (callback && callback.error && typeof callback.error === "function") {
                    callback.error.call(callback.scope || global, textStatus, errorThrown, jqXHR);
                }
            }
        });
    }

    getData(url: string, callback: IApiCallback): void {
        this.data(url, Method.Get, null, callback, DataType.Json);
    }

    getDataJsonP(url: string, callback: IApiCallback): void {
        this.data(url, Method.Get, null, callback, DataType.JsonP);
    }

    postData(url: string, data: any, callback: IApiCallback): void {
        this.data(url, Method.Get, data, callback, DataType.Json);
    }
}

enum Method {
    Post = "POST",
    Get = "GET"
}

enum DataType {
    Xml = "xml",
    Html = "html",
    Json = "json",
    JsonP = "jsonp",
    Text = "text",
}