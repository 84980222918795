﻿import { UrlManager } from "./UrlManager";
import { ListPageHelper } from "./ListPageHelper";
import { Utilities } from "./Utilities";
import { ApiClient } from "../ApiClient";

export class ListPageHelperFactory {
    urlManager: IUrlManager;
    utilities: Utilities;
    apiClient: IApiClient;
    messageManager: IMessageManager;

    constructor(urlManager: IUrlManager, utilities: Utilities, apiClient: IApiClient, messageManager: IMessageManager) {
        this.urlManager = urlManager;
        this.utilities = utilities;
        this.apiClient = apiClient;
        this.messageManager = messageManager;
    }

    create(containerCls: string, itemCls:string, controllerUri: string, action:string, page: number):ListPageHelper {
        let me:this = this;

        return new ListPageHelper(containerCls, itemCls, controllerUri, action, page, me.apiClient,
            me.urlManager, me.messageManager, me.utilities);
    }
}