﻿import { Utilities } from "../Utilities/Utilities";

export class CertificationSearch {
	readonly mobileWidthBreakpoint: number = 768;
	readonly containerCls: string = "certification-search";
	readonly loadingCls: string = "loading";

	utilities: Utilities;
	certificationSearchService: ICertificationSearchService
	messageManager: IMessageManager;


	containerElement: JQuery<HTMLElement>;
	resultElement: JQuery<HTMLElement>;
	codeElement: JQuery<HTMLElement>;
	surnameElement: JQuery<HTMLElement>;
	submitElement: JQuery<HTMLElement>;

	constructor(utilities: Utilities, certificationSearchService: ICertificationSearchService, messageManager: IMessageManager) {
		let me: this = this,
			element: JQuery<HTMLElement> = jQuery(`.${me.containerCls}`),
			resultEl: JQuery<HTMLElement> = element.find('.result');


		me.utilities = utilities;
		me.messageManager = messageManager;
		me.certificationSearchService = certificationSearchService;

		if (element.length === 1) {
			me.containerElement = element;
			me.resultElement = resultEl;
			me.initCertificationSearch();
		}

	}

	initCertificationSearch(): void {
		let me: this = this,
			codeEl: JQuery<HTMLElement> = me.containerElement.find("#code"),
			surnameEl: JQuery<HTMLElement> = me.containerElement.find("#surname"),
			submitEl: JQuery<HTMLElement> = me.containerElement.find("a.btn.submit"),
			formEl: JQuery<HTMLElement> = me.containerElement.find("form");

		if (codeEl.length == 0 || surnameEl.length == 0) {
			throw "No code or surname element";
		}

		me.codeElement = codeEl;
		me.surnameElement = surnameEl;
		me.submitElement = submitEl;

		me.submitElement.off("click").click(function (event: JQuery.Event): void {
			me.searchCertificate();
		});

		formEl.off("submit").submit(function (event: JQuery.Event): void {
			event.preventDefault();
			me.searchCertificate();
		});
	}

	searchCertificate(): void {
		let me: this = this,
			code = me.codeElement.val() as string,
			surname = me.surnameElement.val() as string,
			valid: boolean = true;

		me.clearErrors();

		if (code.trim().length == 0) {
			me.codeElement.parentsUntil('.form-group').parent().addClass('has-error');
			valid = false;
		}

		if (surname.trim().length == 0) {
			me.surnameElement.parentsUntil('.form-group').parent().addClass('has-error');
			valid = false;
		}

		if (valid) {
			this.runSearchCertificate(code, surname);
		}
	}

	runSearchCertificate(code: string, surname: string): void {
		let me: this = this,
			apiCallback: IApiCallback = {
				success: (response: ICertificationSearchResponse): void => {
					let responseHtml: string = "";

					if (response.Success) {
						responseHtml = "No matching certificate";

						if (response.Result.length > 0) {
							responseHtml = me.getResultHtml(response.Result);
						}

					} else {
                        response.Exceptions.forEach((error: IError): void => {
                            // Displaying meaningful validation message without breaking the API
                            if (error.Code == -1 && error.Message == "Information invalid.") {
								error.Message = "We couldn't find any items matching for your search.Please try a different search";
							}

							responseHtml += `<p class="text-danger">${error.Message}</p>`;
						});
					}

					me.resultElement.html(responseHtml);

				},
				complete: (textStatus: string) => {
					jQuery(me.containerElement).removeClass(me.loadingCls);
				},
				error: (textStatus: string, errorThrown: string) => {
					me.messageManager.showApiErrorMessage(errorThrown);
				},
				scope: me
			};

		if (!jQuery(me.containerElement).hasClass(me.loadingCls)) {
			jQuery(me.containerElement).addClass(me.loadingCls);
			me.resultElement.html("");

			me.certificationSearchService.searchCertificate(code, surname, apiCallback)
		}
	}

	getResultHtml(certifications: ICertification[]): string {
		let me: this = this,
			resultHtml = '<table class="table table-hover"><thead><tr>'
				+ `<th>${me.utilities.escapeHtml(certifications[0].CertificationTerm)}</th>`
				+ `<th>${me.utilities.escapeHtml(certifications[0].CodeLabel)}</th>`
				+ '<th>Name</th>'
				+ '<th>Organisation</th>'
				+ '<th>Status</div>'
				+ '<th>Expiry date</th>'
				+ '</tr></thead>';

		certifications.forEach((cert: ICertification): void => {
			let organisationName: string = (cert.OrganisationName ? me.utilities.escapeHtml(cert.OrganisationName) : "&nbsp;");
			let expiryDate: string = cert.ExpiryDate ? cert.ExpiryDate : "Never";

			resultHtml += '<tr>'
				+ `<td>${me.utilities.escapeHtml(cert.CertificationName)}</td>`
				+ `<td>${me.utilities.escapeHtml(cert.Code)}</td>`
				+ `<td>${me.utilities.escapeHtml(cert.FirstName)} ${me.utilities.escapeHtml(cert.Surname)}</td>`
				+ `<td>${organisationName}</td>`
				+ `<td>${me.utilities.escapeHtml(cert.Status)}</td>`
				+ `<td>${me.utilities.escapeHtml(expiryDate)}</td>`
				+ '</tr>';
		});

		resultHtml += "</table>";

		return resultHtml;
	}

	clearErrors(): void {
		let me: this = this;

		me.containerElement.find(".has-error").removeClass("has-error");
	}
}

