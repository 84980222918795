﻿export class WalkMeBubbleHelper {
    readonly togglerCls: string = "activate-walkme-menu";
    readonly togglerParentCls: string = "button-wrapper";
    readonly walkmeMenuID: string = "walkme-menu";
    readonly walkmeTabsID: string = "walkme-tabs";

    togglerElement: JQuery<HTMLElement>;
    togglerParentElement: JQuery<HTMLElement>;

    constructor() {
        let me: this = this;

        me.togglerElement = jQuery(`.${me.togglerCls}`);
        me.togglerParentElement = jQuery(`.${me.togglerParentCls}`);

        window.walkme_ready = function () {
            jQuery(document).trigger(window.WalkMeReadyEventName);
        }

        window.walkme_player_event = function (eventData) {
            jQuery(document).trigger(window.WalkMeEventName, { detail: eventData });
        };

        window.walkme_event = function (eventData) {
            jQuery(document).trigger(window.WalkMeEventName, { detail: eventData });
        };

        // Google Analytics tracking events
        jQuery(document).on(window.WalkMeEventName, me.addGoogleAnalyticsEvents);
    }

    initWalkMeBubble(): void {
        let me: this = this;

        if (me.togglerElement.length > 0) {
            me.trialWalkMeExperience();
        }
    }

    addGoogleAnalyticsEvents(event) {
        if (['WalkthruAborted', 'FlowStopped', 'UserStoppedWalkthruAfterStop'].indexOf(event.detail.Type) != -1) {
            if (typeof window.ga !== 'undefined') {
                window.ga('send', 'event', 'WalkMe', event.detail.Type, event.detail.ExtraData.businessSolutionId + ':' + event.detail.ExtraData.stageId);
                window.walkme_current_flow = null;
            }
        }
    }

    trialWalkMeExperience(): void {
        let me: this = this;

        me.togglerElement.click(():void => {
            if (typeof window.WalkMePlayerAPI !== 'undefined') {
                window.WalkMePlayerAPI.toggleMenu();
            }
        });

        jQuery(document).click((event: JQuery.Event): void => {
            let parent = jQuery(event.target).closest(`.${me.togglerParentCls}, #${me.walkmeMenuID}`);
            if (!parent || parent.length < 1) {
                if (typeof window.WalkMePlayerAPI !== 'undefined' && window.WalkMePlayerAPI.isMenuOpen()) {
                    window.WalkMePlayerAPI.toggleMenu();
                }
            }
        });

        jQuery(document).on(window.WalkMeEventName, (event: JQuery.Event):void => {
            let walkmeMenuElement = jQuery(`#${me.walkmeMenuID}`);

            if (walkmeMenuElement) {
                if ((event.type == "BeforeMenuOpen" || event.type == "MenuBuilt")) {
                    if (me.isTrialDemo() && me.hasOnboardingTasks()) {
                        let walkmeTabsElement = jQuery(`#${me.walkmeTabsID}`);

                        if (walkmeTabsElement) {
                            walkmeTabsElement.first().css('display', 'none');
                        }
                    }
                } else if (event.type == "AfterMenuOpen") {
                    walkmeMenuElement.css('width', me.togglerParentElement.width() + 'px');
                }
            }
        });

        jQuery(document).scroll((event: JQuery.Event): void => {
            if (typeof window.WalkMePlayerAPI !== 'undefined' && window.WalkMePlayerAPI.isMenuOpen()) {
                window.WalkMePlayerAPI.toggleMenu();
            }
        });
    }


    // UTIL

    /** Matches the TrialDemo segmentation in WalkMe */
    isTrialDemo() {
        let me: this = this;
        // boolean variables are strings for WalkMe compat
        return (window.ArloWalkMe.platform.isDemoCompany == "True" && window.ArloWalkMe.platform.isTrial == "True");
    };

    hasOnboardingTasks() {
        let me: this = this;

        return (jQuery(`#${me.walkmeMenuID}`) && jQuery(`#${me.walkmeMenuID}`).children.length > 2);
    }
}
/* tslint:disable */

declare global {
    interface Window {
        ga: any;
        walkme_ready: any;
        walkme_player_event: any;
        walkme_event: any;
        walkme_current_flow: any;
        WalkMeReadyEventName: string;
        WalkMeEventName: string;
        WalkMePlayerAPI: any;
        ArloWalkMe: any;
    }
}

/* tslint:enable */
