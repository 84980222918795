﻿import { UrlManager } from "../Utilities/UrlManager";
import { Utilities } from "../Utilities/Utilities";
import { SelectizeHelper } from "../Utilities/SelectizeHelper";
import { ApiClient } from "../ApiClient";
import { CookieManager } from "../Utilities/CookieManager";
import { InViewPort } from "../Utilities/InViewPort";

export class Webinars {
    readonly action: string = "GetWebinarsList";
    readonly containerCls: string = "webinars-container";
    readonly contentCls: string = "webinars-list-container";
    readonly loadingCls: string = "loading";

    urlManager: IUrlManager;
    utilities: Utilities;
    apiClient: IApiClient;
    selectizeHelper: SelectizeHelper;
    messageManager: IMessageManager;
    cookieManager: ICookieManager;

    filterPrefixConfig: IFilterPrefixConfig;
    controllerUri: string;

    containerElement: HTMLElement = null;
    isQueryRunning: boolean = false;
    pageID: number = 1;

    constructor(apiClient: IApiClient, utilities: Utilities, urlManager: IUrlManager,
        messageManager: IMessageManager, cookieManager: ICookieManager, selectizeHelper: SelectizeHelper, pageID: number, controllerUri: string,
        filterPrefixConfig: IFilterPrefixConfig) {
        let me: this = this,
            element: JQuery<HTMLElement> = jQuery(`.${me.containerCls}`);

        me.apiClient = apiClient;
        me.utilities = utilities;
        me.urlManager = urlManager;
        me.messageManager = messageManager;
        me.selectizeHelper = selectizeHelper;
        me.cookieManager = cookieManager;

        me.filterPrefixConfig = filterPrefixConfig;
        me.controllerUri = controllerUri;

        if (element.length === 1) {
            me.containerElement = element[0];
            me.initListeners();
            me.initScrollEvent();
            me.initContent();
            me.pageID = pageID;
        }

        jQuery(document).ready(() => {
            me.needToLoadNext();
            setTimeout((): void => { me.jumpToPage() }, 200);
        });
    }

    initScrollEvent(): void {
        let me: this = this,
            loadMore: Function = me.utilities.debounce((): void => {
                me.needToLoadNext();
            }, 100, false);

        window.onscroll = (ev: UIEvent): any => {
            loadMore();
        };
    }

    initContent(): void {
        let me: this = this;

        me.initPreviousNextListeners();
    }

    initListeners(): void {
        let me: this = this;

        jQuery(me.containerElement).find("#filters button").click((): void => {
            me.pageID = 1;
            me.getWebinarsList();
        });

        jQuery("#filter-timezones").change((): void => {
            me.timeZoneHasChanged();
            me.getWebinarsList();
        });

        jQuery("#filters input[type=radio]").off("change").change((): void => {
            me.pageID = 1;
            me.getWebinarsList();
        });

        me.initPreviousNextListeners();
    }

    initPreviousNextListeners(): void {
        let me: this = this;

        jQuery(".btn.showmore").click(function (event: JQuery.Event): void {
            event.preventDefault();

            me.pageID++;
            me.getWebinarsList(false);
        });
    }

    needToLoadNext(): void {
        let me: this = this,
            showMoreBtn: JQuery<HTMLElement> = jQuery(me.containerElement).find(".btn.showmore");
        if (!me.isQueryRunning && showMoreBtn.length === 1) {
            if (InViewPort.isAnyPartOfElementInViewport(showMoreBtn[0])) {
                me.pageID++;
                me.getWebinarsList(false);
            }
        }
    }

    jumpToPage(): void {
        let me: this = this,
            pageElement: JQuery<HTMLElement> = jQuery(me.containerElement).find(`.webinar.page-${me.pageID}:first`);

        if (me.pageID > 1 && pageElement.length > 0) {
            me.utilities.scrollToElement(pageElement);
        }
    }

    getWebinarsList(showFullScreenLoader:boolean = true): void {
        let me: this = this,
            categoryIDs: number[] = [];

        if (me.isQueryRunning) {
            return;
        }

        jQuery(me.containerElement).find("#filters input:checked").map(function (index, el) {
            categoryIDs.push(parseInt($(el).val() as string));
        });

        let loaderContainerElement: JQuery<HTMLElement> = showFullScreenLoader ? jQuery(me.containerElement) : jQuery(me.containerElement).find(".showmore-container"),
            ajaxUrl: string = me.constructAjaxUrl(categoryIDs, me.pageID),
            url: string = me.constructUrl(categoryIDs, me.pageID),
            apiCallback: IApiCallback = {
                success: (data: any): void => {
                    jQuery(me.containerElement).find(`.${me.contentCls}`).html(data.html);
                    me.initContent();
                    me.urlManager.pushState(null, document.title, url);
                },
                complete: (textStatus: string) => {
                    jQuery(loaderContainerElement).removeClass(me.loadingCls);
                    me.isQueryRunning = false;
                },
                error: (textStatus: string, errorThrown: string) => {
                    me.messageManager.showApiErrorMessage(textStatus);
                },
                scope: me
            };

        me.isQueryRunning = true;
        jQuery(loaderContainerElement).addClass(me.loadingCls);
        me.apiClient.getData(ajaxUrl, apiCallback);
    }

    constructAjaxUrl(categoryIDs: number[], pageID: number): string {
        let me: this = this,
            url: string = `${me.controllerUri}/${me.action}/`,
            parts: string[] = [];

        if (categoryIDs.length > 0) {
            categoryIDs.forEach((value) => {
                parts.push("selectedCategoryIDs=" + value + "");
            });
        }

        if (pageID > 1) {
            parts.push("pageID=" + pageID);
        }

        if (parts.length > 0) {
            url += "?" + parts.join("&");
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    constructUrl(categoryIDs: number[], pageID: number): string {
        let me: this = this,
            url: string = `${me.controllerUri}`;

        // the order of this is important because the routes are set up in the same order
        if (categoryIDs.length > 0) {
            url += `/${me.filterPrefixConfig.category}-` + categoryIDs.join("-");
        }

        if (pageID > 1) {
            url += `/${me.filterPrefixConfig.paging}-${pageID}`;
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    timeZoneHasChanged(): void {
        let me: this = this;

        let timeZoneID: string = jQuery("#filter-timezones").val() as string;

        me.cookieManager.setCookie("TimeZoneID", timeZoneID.toString(), 365);
    }
}

