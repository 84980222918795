﻿export class CookieManager implements ICookieManager {
    cookieHandler: any;

    constructor(cookieHandler:any) {
        let me:this = this;

        me.cookieHandler = cookieHandler;
    }

    setCookie(name:string, value:string, expires?:number, path?:string, domain?: string, secure?: boolean):void {
        let me:this = this,
            options:any = {};

        if (expires && expires > 0) {
            options.expires = expires;
        }

        if (path && path !== "") {
            options.path = path;
        }

        if (domain && domain !== "") {
            options.domain = path;
        }

        if (secure && secure === true) {
            options.secure = secure;
        }

        me.cookieHandler.set(name, value, options);
    }

    getCookie(name:string):object {
        let me:this = this;

        return me.cookieHandler.get(name);
    }
}
