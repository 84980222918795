export class InViewPort {
    static isElementInViewport(el:HTMLElement):boolean {
        const rect: ClientRect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }
    static isAnyPartOfElementInViewport(el:HTMLElement, part?:number):boolean {
        part = part != null ? part : 0;

        const rect: ClientRect = el.getBoundingClientRect();
        const windowHeight:number = (window.innerHeight || document.documentElement.clientHeight);
        const windowWidth:number = (window.innerWidth || document.documentElement.clientWidth);

        const vertInView:boolean = (rect.top <= windowHeight) && ((rect.top + rect.height) >= part * rect.height);
        const horInView:boolean = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

        return (vertInView && horInView);
    }

    static isElementMostlyVisible(el:HTMLElement):boolean {
        return this.isAnyPartOfElementInViewport(el, 0.5);
    }
}