﻿import { ListPageHelperFactory } from "../Utilities/ListPageHelperFactory";
import { ListPageHelper } from "../Utilities/ListPageHelper";

export class VenueList {
	readonly containerCls: string = "venue-list";
	readonly loadingCls: string = "loading";
	readonly itemCls: string = "venue";
    readonly action:string = "GetVenueList";

	listPageHelper: ListPageHelper;

	containerElement: HTMLElement = null;
	isQueryRunning: boolean = false;
	controllerUri: string = null;
	urlManager: IUrlManager = null;
	apiClient: IApiClient = null;
	listPageHelperFactory = null;
	pageID: number = 1;
	parameterKeys = null;
	parameterValues = null;


	constructor(listPageHelperFactory: ListPageHelperFactory, page: number, controllerUri: string, urlManager: IUrlManager, apiClient: IApiClient) {
		let me: this = this;
		me.controllerUri = controllerUri;
		me.listPageHelperFactory = listPageHelperFactory;
		me.apiClient = apiClient;
		me.urlManager = urlManager;
		me.pageID = page;
		me.containerElement = jQuery(`.${me.containerCls}`)[0];

		if (me.containerElement) {
			me.initListeners();
			me.initContent();
		}
	}

	initListeners(): void {
		let me: this = this;
		jQuery("#filter-locations").change((): void => {
			let location = jQuery("#filter-locations").val();

			me.listPageHelper.parameterKeys = [];
			me.listPageHelper.parameterValues = {};

			if (location) {
				me.listPageHelper.parameterKeys.push("location");
				me.listPageHelper.parameterValues["location"] = location;
			}

			me.pageID = 1;
			me.listPageHelper.reload();
			
		});
	}

	constructAjaxUrl(location: string): string {
		let me: this = this,
			url: string = `${me.controllerUri}/${me.action}/`;
		url += "?location=" + location;
		return me.urlManager.getAbsoluteUrl(url);
	}

	constructUrl(location: string): string {
		let me: this = this,
			url: string = `${me.controllerUri}`;
		url += "?location=" + location;
		return me.urlManager.getAbsoluteUrl(url);
	}

	initContent(): void {
		let me: this = this;
		me.listPageHelper = me.listPageHelperFactory.create(me.containerCls, me.itemCls, me.controllerUri, me.action, 1);

		if (jQuery("#filter-locations").val()) {
			me.listPageHelper.parameterKeys.push("location");
			me.listPageHelper.parameterValues["location"] = jQuery("#filter-locations").val().toString();
		}

		me.listPageHelper.needToLoadPrev();
	}
}

