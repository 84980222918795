﻿import { Utilities } from "./Utilities";

export class CertificationSearchService implements ICertificationSearchService {
    readonly serviceUrl: string = "Website/Resources/Services/CertificationSearchService.svc/Search";

    baseUrl: string;
    utilities: Utilities;
    apiClient: IApiClient;

    constructor(baseUrl: string, utilities: Utilities, apiClient: IApiClient) {
        let me: this = this;

        me.baseUrl = baseUrl;
        me.utilities = utilities;
        me.apiClient = apiClient;
    }

    searchCertificate(code: string, surname: string, callback: IApiCallback): void {
        let me: this = this,
            serviceUrl = me.utilities.ensureTrailingSlash(me.baseUrl) + me.serviceUrl,
            certificateSearchUrl = me.utilities.removeTrailingSlash(serviceUrl) + "?code=" + encodeURIComponent(code) + "&surname=" + encodeURIComponent(surname);

        me.apiClient.getDataJsonP(certificateSearchUrl, callback);
    }
}
