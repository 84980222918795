﻿import { Utilities } from "./Utilities";

export default class AuthenticationManager {

    storageKey: string = 'LS.UserDetails';
    apiBaseUri: string = '/api/2012-02-01/pub/resources/newportal';
    webBaseUrl: string;
    portalEnabled: boolean;
    IsLoggedIn: boolean = false;

    constructor(portalEnabled: boolean, webBaseUrl: string) {
        this.webBaseUrl = webBaseUrl;
        this.portalEnabled = portalEnabled;

        var me = this;
        window.addEventListener('userAuthChange', () => { this.userAuthChange(me); });

        this.init();
    }

    async init() {
        this.IsAuthenticated();

        return jQuery.Deferred(); //not necessary except for current TS build configuration
    }

    userAuthChange(me: AuthenticationManager) {
        var currentUser = me.getCurrentUser();
        var $portalLoginName = jQuery('.portal-login-name');
        var $portalLoginMenu = jQuery('.portal-login-menu');
        var $portalMenuButton = jQuery("#portal-menu-button");
        var $portalMenu = jQuery("#portal-menu");
        var $portalLogin = jQuery('.portal-login');
        if (me.portalEnabled && currentUser != null && !currentUser.IsGuest) {
            me.IsLoggedIn = true;
            $portalLoginName.text(currentUser.FirstName + ' ' + currentUser.LastName);
            $portalLogin.addClass('authenticated');
            $portalLoginMenu.addClass('portalEnabled');
            $portalMenuButton.addClass('portalEnabled');
            $portalMenu.addClass('portalEnabled');
        } else {
            me.IsLoggedIn = false;
            $portalLoginName.text('Login');
            $portalLogin.removeClass('authenticated');
            $portalLoginMenu.removeClass('portalEnabled');
            $portalMenuButton.removeClass('portalEnabled');
            $portalMenu.removeClass('portalEnabled');
        }
    }

    private getCurrentUser(): any {

        var user = sessionStorage[this.storageKey];
        if (!user) {
            return null;
        }
        return JSON.parse(user);
    }

    async IsAuthenticated() {
        var me = this;
        return jQuery.ajax({
            url: this.apiBaseUri + '/authenticate',
            type: 'GET'
        }).then(function (user) {
            window.sessionStorage.setItem(me.storageKey, (<any>window).JSON.stringify(user));
        })
        .fail(function () {
            window.sessionStorage.removeItem(me.storageKey);
        })
        .always(function () {
            window.dispatchEvent(new Event('userAuthChange'));
        });
    }
}
