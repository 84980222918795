﻿import { ListPageHelperFactory } from "../Utilities/ListPageHelperFactory";
import { ListPageHelper } from "../Utilities/ListPageHelper";
import { Utilities } from "../Utilities/Utilities";

export class PresenterList {
    readonly containerCls:string = "presenter-list";
    readonly itemCls:string = "presenter";
    readonly action:string = "GetPresenterList";

    listPageHelper:ListPageHelper;
    utilities: Utilities;

    constructor(listPageHelperFactory: ListPageHelperFactory, page: number, controllerUri: string) {
        let me:this = this;

        me.listPageHelper = listPageHelperFactory.create(me.containerCls, me.itemCls, controllerUri, me.action, page);
        me.listPageHelper.needToLoadPrev();
    }
}

