export class EventTileFlip {
    static initUpcomingEvents(upcomingItemCls): void {
        let upcomingEventElements: JQuery<HTMLElement> = jQuery(`.${upcomingItemCls}`);

        // Adds event listeners on the tile for flipping the tile when hovered.
        upcomingEventElements.hover((event: JQuery.Event): void => {
            jQuery(event.currentTarget).addClass("hover");
        }).mouseleave((event: JQuery.Event): void => {
            jQuery(event.currentTarget).removeClass("hover");
        }).on("touchstart", (): void => {
            jQuery(event.currentTarget).toggleClass("hover");
        });

        // Flips when an element on the back of the tile is focused. Also flips when focus leaves the back of the tile. Allows for keyboard navigation.
        let upcomingEventBack: JQuery<HTMLElement> = jQuery(`.${upcomingItemCls} .card-back`);
        upcomingEventBack.focusin((event: JQuery.Event): void => {
            jQuery($(event.target).closest(`.${upcomingItemCls}`)).addClass("hover");
        }).focusout((event: JQuery.Event): void => {
            jQuery($(event.target).closest(`.${upcomingItemCls}`)).removeClass("hover");
        });
    }
}
