﻿export class MessageManager implements IMessageManager {
    toastr:any;

    constructor(toastr: any) {
        let me:this = this;

        me.toastr = toastr;

        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
    }

    showErrorMessage(title:string, message: string):void {
        let me:this = this;

        me.toastr.error(message, title);
    }

    showSuccessMessage(title:string, message: string):void {
        let me:this = this;

        me.toastr.success(message, title);
    }

    showWarningMessage(title:string, message: string):void {
        let me:this = this;

        me.toastr.warning(message, title);
    }

    showInfoMessage(title:string, message: string):void {
        let me:this = this;

        me.toastr.info(message, title);
    }

    showApiErrorMessage(textStatus:string):void {
        let me:this = this,
            message:string = "There was an error while requesting data from the server";

        if (textStatus !== "") {
            message += ": " + textStatus;
        }

        me.showErrorMessage("Error", message);
    }
}
